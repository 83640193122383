import { DataSource } from '@angular/cdk/table'
import { BehaviorSubject, Observable } from 'rxjs'

export class CdkTableDataSource<T> extends DataSource<T> {
  public set data(data: T[]) {
    this._data.next(data)
  }
  public get data() {
    return this._data.value
  }
  private _data: BehaviorSubject<T[]>

  constructor(data: T[] = []) {
    super()
    this._data = new BehaviorSubject(data)
  }

  connect(): Observable<T[]> {
    return this._data
  }

  disconnect() {
    this._data.complete()
  }
}
